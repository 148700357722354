import { Provider_Login } from "../../config/api/index";
import { Errors, Success } from "../../components/antdToast";

export function SetProviderLoginDataInAction(data, props, props1) {
  return (dispatch) => {
    Provider_Login(
      data,
      (success) => {
        if (success?.status === 200) {
          Success(success?.data?.response?.message);

          props.setLoaderState(false);
          if (success?.data?.response?.detail?.type === "service_provider") {
            success?.data?.response?.detail?.admin?.stripe_user_id === null
              ? window.location.replace(
                  `https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_NRiQIMip0EtIxx3Z6TnGDuTeQ9ZSbuN1&scope=read_write&state=${success?.data?.response?.detail?.admin?.service_provider_id}`
                )
              : dispatch({
                  type: "PROVIDER_LOGGED_USER",
                  payload: success?.data?.response?.detail,
                });
            localStorage.setItem("type", success?.data?.response?.detail?.type);
            localStorage.setItem(
              "token",
              success?.data?.response?.detail?.token
            );
            props1.history.push("/dashboard");

            // props1.history.push("/profile");
          } else if (success?.data?.response?.detail?.type === "co_worker") {
            dispatch({
              type: "PROVIDER_LOGGED_USER",
              payload: success?.data?.response?.detail,
            });
            localStorage.setItem(
              "token",
              success?.data?.response?.detail?.token
            );
            localStorage.setItem("type", success?.data?.response?.detail?.type);
            props1.history.push("/worker/dashboard");
          }
        }
      },
      (error) => {
        if (error?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.data?.error?.message);
        } else if (error?.status === 422) {
          props.setLoaderState(false);
          Errors(error?.data?.error?.message);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
}
