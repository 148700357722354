import moment from "moment";
import { providerLabels } from "../../config/label/providerLabels";
import { Images } from "../../constant/Images";
import "./UserDetailContainerCard.css";
const UserDetailContainerCard = ({ dividerShow = false, clientDetail }) => {
  const { userProfilePlaceholder } = Images;

  return (
    <div className="bg-white rounded-lg py-1">
      <div className=" p-6  pl-3 pt-0 pb-3 flex w-full justify-between items-center">
        <div style={{ display: "flex" }} className="p-5 pb-0">
          <img
            className="rounded-full"
            src={
              clientDetail?.avatar
                ? clientDetail?.avatar
                : userProfilePlaceholder.default
            }
            style={{ height: 48, width: 48 }}
          />
          {clientDetail !== "" && (
            <div className="pl-2">
              <strong className=" ">
                {clientDetail?.data?.[0]?.personal?.firstName +
                  " " +
                  clientDetail?.data?.[0]?.personal?.lastName}
              </strong>
              <p className="">{providerLabels.client}</p>
            </div>
          )}
        </div>
      </div>
      <div className="bg-white container rounded-lg ">
        <div className="grid grid-cols-2 p-6 pl-3 pt-0 pb-3">
          {/* /////////////////details */}
          <div className="" style={{ position: "relative" }}>
            {dividerShow && (
              <div
                style={{
                  border: "1px solid gray",
                  opacity: 0.2,
                  marginBottom: "15px",
                }}
              />
            )}
            <div style={{ paddingLeft: 20 }}>
              <b>{providerLabels.phone}</b>
              <p>{clientDetail?.data?.[0]?.personal?.countryCode}</p>
            </div>
          </div>
          <div className="" style={{ position: "relative" }}>
            {dividerShow && (
              <div
                style={{
                  border: "1px solid gray",
                  opacity: 0.2,
                  marginBottom: "15px",
                }}
              />
            )}
            <div style={{ paddingLeft: 20 }}>
              <b>{providerLabels.orderDate}</b>
              <p>{moment(clientDetail?.created_at).format("DD-MMM-YYYY")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white container rounded-lg ">
        <div className="grid grid-cols-2 p-6 pl-3 pt-0 pb-3">
          {/* /////////////////details */}
          <div className="" style={{ position: "relative" }}>
            {dividerShow && (
              <div
                style={{
                  border: "1px solid gray",
                  opacity: 0.2,
                  marginBottom: "15px",
                }}
              />
            )}
            <div style={{ paddingLeft: 20 }}>
              <b>{providerLabels.region}</b>
              <p>{clientDetail?.data?.[1].region?.state}</p>
            </div>
          </div>
          <div className="" style={{ position: "relative" }}>
            {dividerShow && (
              <div
                style={{
                  border: "1px solid gray",
                  opacity: 0.2,
                  marginBottom: "15px",
                }}
              />
            )}
            <div style={{ paddingLeft: 20 }}>
              <b>{providerLabels.address}</b>
              <p>{clientDetail?.data?.[1].region?.city}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserDetailContainerCard;
