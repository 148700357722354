import {
  BellOutlined,
  DeleteOutlined,
  DownOutlined,
  FileTextOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Collapse,
  Dropdown,
  Empty,
  List,
  Menu,
  Spin,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";
import ReactQuill from "react-quill";

import {
  child,
  push as fbPush,
  onValue,
  ref,
  runTransaction,
  serverTimestamp,
  update,
} from "firebase/database";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ModalComponent } from "../../components";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import { Remove_Data_Form_Provider_Reducers } from "../../components/dataRemoveOnProviderLogout";
import InformationContainer from "../../components/informationContainer";
import InputComponent from "../../components/InputComponent";
import {
  AddAdditonalBudgetMethod,
  GetOrderNotificationById,
  GetOrdersById,
  SendOrderMessageMethod,
  UploadFileMethod,
} from "../../config/api";
import myConstant from "../../config/constants";
import { firebaseDatabase } from "../../config/Firebase";
import { providerLabels } from "../../config/label/providerLabels";
import {
  europeanCurrencyFormatter,
  extractContent,
  functionToConvertStringToCamelCase,
  functionToPreventSignsFromNumberFromPricing,
  regexForPricing,
  regexToCheckIfTheStringContainsEmail,
  regexToReplaceDecimalsWithComma,
} from "../../config/utils";
import { Images } from "../../constant/Images";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction,
} from "../../Redux/actions/fetchWorkersAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";
import "./myMessageOrder.css";
import "./user-provider-chat.css";

const userType = localStorage.getItem("type");

const format = "HH:mm";
let urlArray = [];

const AddMyMessageOrder = (props) => {
  const { goBack, push } = useHistory();
  const messagesEndRef = useRef();

  const [filterModal, setFilterModal] = useState(false);
  const [message, setMessage] = useState("");
  const [value, setValue] = useState("");
  const [urls, setUrls] = useState([]);
  const [addBudgetUrls, setAddBudgetUrls] = useState([]);
  const [muiltipleDocument, setMuiltipleDocument] = useState("");
  const [additionalBudgetDocument, setAdditionalBudgetDocument] = useState("");
  const [budget, setBudget] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState("");
  const [current, setCurrent] = useState(0);
  const [isSupplementaryCharges, setIsSupplementaryCharges] = useState(false);
  const [supplementaryCharges, setSupplementaryCharges] = useState([]);
  const [userProviderChat, setUserProviderChat] = useState([]);
  const [chatLoading, setChatLoading] = useState(true);

  const [stepsIncrement, setStepsIncrement] = useState([
    {
      id: Math.round(Math.random() * 100),
      name: "",
      price: 0,
    },
  ]);
  const [fieldIsRequired, setFieldIsrequired] = useState(false);
  const [isBudgetSendLoading, setIsBudgetSendLoading] = useState(false);
  const [chatSideBar, setChatSideBar] = useState(false);
  const [myChatDocuments, setMyChatDocuments] = useState([]);
  const [userChatDocuments, setUserChatDocuments] = useState([]);
  const [adminChatDocuments, setAdminChatDocuments] = useState([]);
  const [providerSignature, setProviderSignature] = useState(
    props?.providerDetail?.type === "service_provider"
      ? props?.providerDetail?.admin
      : props?.providerDetail?.admin?.provider
  );
  const [orderNotification, setOrderNotification] = useState([]);
  const [index, changeIndex] = useState(0);
  const [serviceTimeType, setServiceTimeType] = useState([
    "Minute",
    "Hour",
    "Week",
    "Day",
  ]);
  const [selectedTimeType, setSelectedTimeType] = useState("Minute");
  const [serviceTime, setServiceTime] = useState(1);
  const [visible, setVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [disputedOrder, setDisputedOrder] = useState(false);
  const [fileUploadLoader, setUploadLoader] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    fetchServiceProviderOrderNotifications(id);
    fireBaseListenerToGetMessages();
    getOrdersByProvider(id);

    const interval = setInterval(() => {
      fetchServiceProviderOrderNotifications(id);
    }, 20000);
    return () => {
      runTransaction(
        ref(firebaseDatabase, "orders/" + id + "/providerUnread"),
        () => {
          return 0;
        }
      );
      clearInterval(interval);
    };
  }, []);

  function scrollToBottom() {
    messagesEndRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "end",
    });
  }
  useEffect(() => {
    scrollToBottom();
  }, [userProviderChat]);
  const getOrdersByProvider = (id) => {
    GetOrdersById(
      id,
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            setSelectedOrder(success?.data?.response?.detail);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };

  const fetchServiceProviderOrderNotifications = (id) => {
    GetOrderNotificationById(
      id,
      (res) => {
        setOrderNotification(res?.data?.response?.detail);
        setChatLoading(false);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const {
    attachedDocument,
    additionalBudgetIcon,
    orderCanceledIcon,
    orderPlacedIcon,
    RequestBudgetIcon,
    document,
    loginLogo,
    uploadImg,
    checkbox,
    checkboxActive,
  } = Images;

  const fireBaseListenerToGetMessages = () => {
    const getData = ref(firebaseDatabase, `orders/${id}/messages`);

    onValue(getData, (snapshot) => {
      const data = snapshot?.val();
      setUserProviderChat(Object?.values(data || {}));

      setChatLoading(false);
      runTransaction(
        ref(firebaseDatabase, "orders/" + id + "/providerUnread"),
        () => {
          return 0;
        }
      );

      setUserChatDocuments(
        Object?.values(data || {})
          ?.reverse()
          ?.filter((document) => {
            return (
              document.is_provider_message == "0" &&
              document.is_admin_message == "0"
            );
          })
      );
      setMyChatDocuments(
        Object?.values(data || {})
          ?.reverse()
          ?.filter((document) => {
            return document.is_provider_message == "1";
          })
      );

      setAdminChatDocuments(
        Object?.values(data || {})
          ?.reverse()
          ?.filter((document) => {
            return document.is_admin_message == "1";
          })
      );
    });
  };

  const firebaseSendMessage = (messages) => {
    // A post entry.
    const postData = {
      is_provider_message: "1",
      service_provider_id: providerSignature?.service_provider_id,
      service_provider: providerSignature,
      admin_id: null,
      admin: null,
      is_admin_message: "0",
      user_id: selectedOrder?.user_id,
      user: selectedOrder?.user,
      message: messages,
      time: serverTimestamp(),
    };

    // Get a key for a new Post.
    const newPostKey = fbPush(child(ref(firebaseDatabase), "posts")).key;

    runTransaction(
      ref(firebaseDatabase, "orders/" + id + "/providerUnread"),
      () => {
        return 0;
      }
    );
    runTransaction(
      ref(firebaseDatabase, "orders/" + id + "/userUnread"),
      (post) => {
        if (post === null) return 1;
        return post + 1;
      }
    );
    runTransaction(
      ref(firebaseDatabase, "orders/" + id + "/adminUnread"),
      (post) => {
        if (post === null) return 1;
        return post + 1;
      }
    );
    // Write the new post's data simultaneously in the posts list and the user's post list.
    const updates = {};

    // updates['/posts/' + newPostKey] = postData;
    updates["/orders/" + id + "/messages/" + newPostKey] = postData;

    return update(ref(firebaseDatabase), updates);
  };

  const sendMessageToUser = () => {
    // if (
    //   regexToCheckIfTheStringContainsPhoneNumber(
    //     extractContent(message?.toLowerCase())
    //   )
    // ) {
    //   Errors(providerLabels.messageToAvoidSharingEmailAndPhoneNumber);
    //   return;
    // }
    // if (
    //   regexToCheckIfTheStringContainsEmail(
    //     extractContent(message?.toLowerCase())
    //   )
    // ) {
    //   Errors(providerLabels.messageToAvoidSharingEmailAndPhoneNumber);
    //   return;
    // }

    // if (
    //   extractContent(message?.toLowerCase())?.includes("email") ||
    //   extractContent(message?.toLowerCase())?.includes("correo electrónico")
    // ) {
    //   Errors(providerLabels.messageToAvoidSharingEmailAndPhoneNumber);
    //   return;
    // }
    // if (
    //   extractContent(message?.toLowerCase())?.includes("phone") ||
    //   extractContent(message?.toLowerCase())?.includes("teléfono")
    // ) {
    //   Errors(providerLabels.messageToAvoidSharingEmailAndPhoneNumber);
    //   return;
    // }

    // if (extractContent(message?.toLowerCase())?.includes("whatsapp")) {
    //   Errors(providerLabels.messageToAvoidSharingEmailAndPhoneNumber);
    //   return;
    // }
    // if (extractContent(message?.toLowerCase())?.includes("linkedin")) {
    //   Errors(providerLabels.messageToAvoidSharingEmailAndPhoneNumber);
    //   return;
    // }
    // if (extractContent(message?.toLowerCase())?.includes("facebook")) {
    //   Errors(providerLabels.messageToAvoidSharingEmailAndPhoneNumber);
    //   return;
    // }

    if (disputedOrder === false) {
      if (message !== "" || urls.length !== 0) {
        let obj = {
          order_id: props.match.params.id,
          is_provider_message: "1",
          message: JSON.stringify([
            {
              message: message === "" ? null : message,
              documents: urls.length === 0 ? null : urls,
            },
          ]),
        };
        firebaseSendMessage([
          {
            message: message === "" ? null : message,
            documents: urls.length === 0 ? null : urls,
          },
        ]);

        SendOrderMessageMethod(
          obj,
          (success) => {
            if (success.status === 200) {
              props.setLoaderState(false);
              Success(success.data.response.message);
              setMessage("");
              setMuiltipleDocument("");
              setUrls([]);
            }
          },
          (error) => {
            if (error?.response?.status === 401) {
              props.setLoaderState(false);
              Errors(error?.response?.data?.message);
              Remove_Data_Form_Provider_Reducers(props);
              push("/");
            } else if (error?.response?.status === 409) {
              Errors(error?.response?.data?.error?.message);
              props.setLoaderState(false);
            } else {
              props.setLoaderState(false);
              Errors("Network Error");
            }
          }
        );
      }
    }
  };
  const handleText = (e, index) => {
    const mappedStepsIncrement = stepsIncrement?.map((value, index1) => {
      if (index1 === index) {
        return {
          name: e.target.value,
          id: value.id,
          price: value?.price,
        };
      } else {
        return value;
      }
    });

    setStepsIncrement(mappedStepsIncrement);
  };
  const handleAmount = (e, index) => {
    const mappedStepsIncrement = stepsIncrement?.map((value, index1) => {
      if (index1 === index) {
        return {
          name: value?.name,
          id: value?.id,
          price: regexForPricing(e.target.value, e.nativeEvent) || 1,
        };
      } else {
        return value;
      }
    });

    setStepsIncrement(mappedStepsIncrement);
  };
  const prev = (id, index) => {
    supplementaryCharges.splice(index, 1);
    deleteStepInput(id);
  };
  // delete antd stepper in dropdownmodal function
  const deleteStepInput = (id) => {
    setCurrent(current - 1);
    let updatedArray = stepsIncrement.filter((item) => {
      return item.id != id;
    });
    setStepsIncrement(updatedArray);
  };
  //stepper add in dropdown modal function
  const next = () => {
    setStepsIncrement((prevState) => {
      setCurrent(current + 1);
      return [
        ...prevState,
        {
          id: Math.round(Math.random() * 100),
          name: "",
          price: 0,
        },
      ];
    });
  };

  const getUrl = (url, name, type, id) => {
    setUrls((prevurls) => [...prevurls, { url, name, type, id }]);
  };
  const selectMuiltipleDocument = async (valueArray) => {
    setUploadLoader(true);
    let muiltipleFileNames = "";
    valueArray?.map((r, i) => {
      muiltipleFileNames += `${r.name}, `;
    });
    valueArray?.map((file, i) => {
      let formData = new FormData();
      formData.append("file", file);
      UploadFileMethod(
        formData,
        (success) => {
          if (success.status === 200) {
            if (success.data.response.detail !== null) {
              let id = Math.round(Math.random() * 10000);
              getUrl(success.data.response.detail, file.name, file.type, id);
            }
            setUploadLoader(false);
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Reducers(props);
            push("/");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
          setUploadLoader(false);
        }
      );
    });
    setMuiltipleDocument(muiltipleFileNames);
  };
  const fileprev = (item, index) => {
    setAddBudgetUrls(addBudgetUrls?.filter((file) => file.id !== item.id));
  };
  const getadditionalBudgetUrl = (url, name, type, id) => {
    setAddBudgetUrls((prevurls) => [...prevurls, { url, name, type, id }]);
  };
  const selectMuiltipleBudgetDocument = async (valueArray) => {
    let muiltipleFileNames = "";
    valueArray?.map((r, i) => {
      muiltipleFileNames += `${r.name}, `;
    });
    valueArray?.map((file, i) => {
      let formData = new FormData();
      formData.append("file", file);
      UploadFileMethod(
        formData,
        (success) => {
          if (success.status === 200) {
            if (success.data.response.detail !== null) {
              let id = Math.round(Math.random() * 10000);
              getadditionalBudgetUrl(
                success.data.response.detail,
                file.name,
                file.type,
                id
              );
            }
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Reducers(props);
            push("/");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
        }
      );
    });
    setAdditionalBudgetDocument(muiltipleFileNames);
  };
  const addOrderBudget = () => {
    if (
      value?.trim()?.length !== 0 &&
      value !== "" &&
      serviceTime !== null &&
      selectedTimeType !== null &&
      budget !== null &&
      Number(regexToReplaceDecimalsWithComma(budget) > -1) &&
      (isSupplementaryCharges
        ? stepsIncrement?.some(
            (supItem) =>
              supItem?.name?.trim()?.length !== 0 || Number(supItem?.price) > 1
          )
        : true)
    ) {
      let obj = {
        order_id: selectedOrder.order_id,
        amount: regexToReplaceDecimalsWithComma(budget || "0"),
        user_id: selectedOrder.user_id,
        service_id: selectedOrder.service_id,
        ...(props?.providerDetail?.type === "service_provider"
          ? {
              service_provider_id: selectedOrder.service_provider_id,
            }
          : {
              service_provider_id:
                props?.providerDetail?.admin?.parent_service_provider_id,
              worker_provider_id:
                props?.providerDetail?.admin?.service_provider_id,

              // worker_provider_id,
            }),
        supplementary_fees:
          isSupplementaryCharges === true
            ? JSON.stringify(
                stepsIncrement?.map((item) => {
                  return {
                    ...item,
                    price: item?.price?.replace(",", "."),
                  };
                })
              )
            : JSON.stringify([]),
        notes: value,
        time: serviceTime,
        time_type: selectedTimeType,
        urls:
          addBudgetUrls.length === 0
            ? JSON.stringify([])
            : JSON.stringify(addBudgetUrls),
      };
      setIsBudgetSendLoading(true);
      setFieldIsrequired(false);
      AddAdditonalBudgetMethod(
        obj,
        (success) => {
          setBudget(null);

          if (success.status === 200) {
            setIsBudgetSendLoading(false);
            Success(success.data.response.message);
            setFilterModal(false);
            setStepsIncrement([
              {
                id: Math.round(Math.random() * 100),
                name: "",
                price: 0,
              },
            ]);
            setAddBudgetUrls([]);
            setEstimatedTime("");
            setValue("");

            setSelectedTimeType("Minute");
            setServiceTime(1);
            setIsSupplementaryCharges(false);
          }
        },
        (error) => {
          setIsBudgetSendLoading(false);

          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Reducers(props);
            push("/");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
        }
      );
    } else {
      setFieldIsrequired(true);
    }
  };

  const handleMenuClick = (e) => {
    setSelectedTimeType(e.key);
    if (e.key === "3") {
      setVisible(false);
    }
  };
  const menu1 = (
    <Menu onClick={handleMenuClick}>
      {serviceTimeType?.map((type) => {
        return (
          <Menu.Item className="capitalize" key={type}>
            {providerLabels[type]}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <Spin spinning={fileUploadLoader} size="large" tip={providerLabels.loading}>
      <section>
        <div className="flex justify-between mt-20 mb-10">
          <div className="pl-5 flex self-center justify-center items-center w-1/2">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{providerLabels.back}</span>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex">
                <h3 className="ml-8	">
                  {providerLabels.orderID}: {props.match.params.id}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container grid grid-cols-12 gap-3 px-2 sm:px-2 lg:px-0 lg:pl-20 ">
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4">
            <div className="bg-white container rounded-lg px-5 py-3 mh64 ">
              <div className="bg-white container rounded-lg col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-3">
                {["completed", "cancelled"]?.includes(
                  selectedOrder?.status
                ) ? null : (
                  <ReactQuill
                    disabled={disputedOrder}
                    className={"richTextBox shadow-lg"}
                    value={message}
                    onChange={(content, delta, source, editor) => {
                      setMessage(content);
                      if (editor.getText().trim()?.length === 0) {
                        setMessage("");
                      }
                    }}
                  />
                )}
              </div>

              <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
                <div className="md:flex">
                  <div className="md:shrink-0">
                    <img
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src={providerSignature.avatar}
                      style={{
                        objectFit: "cover",
                        width: "150px",
                        height: "150px",
                      }}
                      alt="Man looking at item at a store"
                    />
                  </div>
                  <div className="px-4 py-3">
                    <h4 className="capitalize tracking-wide text-sm  font-semibold">
                      <label>{providerSignature.name}</label>
                    </h4>
                    <p className=" text-slate-500">
                      {providerSignature.position}
                    </p>
                    <p className="mb-0 mt-2 text-slate-500">
                      {providerSignature.phone}
                    </p>
                    <p className="text-slate-500">{providerSignature.email}</p>
                  </div>
                </div>
              </div>
            </div>

            {["completed", "cancelled"]?.includes(
              selectedOrder?.status
            ) ? null : (
              <>
                <div className="flex mt-3">
                  <div className="w-1/2 m-2">
                    {selectedOrder?.status === myConstant.dispute ||
                    selectedOrder?.status === myConstant.disputed ? (
                      <ButtonComponent
                        className={"bg-disabled"}
                        title={providerLabels.addBudget}
                      />
                    ) : (
                      <ButtonComponent
                        title={providerLabels.addBudget}
                        onClick={() => {
                          if (disputedOrder === false) {
                            setFilterModal(true);
                          }
                        }}
                      />
                    )}
                  </div>
                  <div className="file btn btn-lg btn-primary uploadDiv">
                    {muiltipleDocument.length > 0
                      ? providerLabels.fileUploaded
                      : providerLabels.upload}
                    <input
                      disabled={disputedOrder}
                      type="file"
                      name="file"
                      className="uploadInput cursor-pointer"
                      multiple
                      accept=".jpg, .jpeg, .png, .doc, .docx, .docs, .pdf, .xls, .csv, .xslx , .docx , .xlsx"
                      onChange={(e) => {
                        if (disputedOrder === false) {
                          let f = e.target.files;
                          let fileArray = [];
                          for (var i = 0; i < f.length; i++) {
                            fileArray.push(f[i]);
                          }
                          selectMuiltipleDocument(fileArray);
                        }
                      }}
                    />
                  </div>
                  <div className="w-1/2 m-2">
                    <ButtonComponent
                      title={providerLabels.send}
                      disabled={disputedOrder || props.loader === true}
                      loader={props.loader === true ? true : false}
                      onClick={sendMessageToUser}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 user-provider-chat-container">
            <Spin
              spinning={chatLoading}
              size="large"
              tip={providerLabels.loading}
            >
              <div className="px-20 " style={{ background: "#FAFAFA" }}>
                <div className="chat-white-color-background flex justify-end border-t-0 border-l-0 border-r-0 border py-2 rounded-t-2xl">
                  <Button
                    shape={"round"}
                    onClick={() => changeIndex(0)}
                    icon={<MessageOutlined color="#5ab3f0" />}
                    className={
                      index === 0
                        ? `icon-button mx-1 bg-primary text-white`
                        : `icon-button mx-1`
                    }
                  >
                    {providerLabels.messages}
                  </Button>

                  <Button
                    shape={"round"}
                    onClick={() => changeIndex(1)}
                    icon={<FileTextOutlined color="#5ab3f0" />}
                    className={
                      index === 1
                        ? `icon-button mx-1 bg-primary text-white`
                        : `icon-button mx-1`
                    }
                  >
                    {providerLabels.documents}
                  </Button>

                  <Button
                    shape={"round"}
                    onClick={() => changeIndex(2)}
                    icon={<BellOutlined color="#5ab3f0" />}
                    // icon={<FileSearchOutlined color="#5ab3f0" />}
                    className={
                      index === 2
                        ? `icon-button mx-1 bg-primary text-white`
                        : `icon-button mx-1`
                    }
                  >
                    {providerLabels.notifications}
                  </Button>
                </div>
                {index === 0 ? (
                  userProviderChat.length === 0 ? (
                    <div
                      className="bg-white flex items-center justify-center flex-col"
                      style={{
                        height: "20rem",
                      }}
                    >
                      <Empty description="" />
                      <span className="capitalize font-bold text-gray-400">
                        {providerLabels.noChatAvailable}
                      </span>
                    </div>
                  ) : (
                    <div
                      className={`bg-white ${
                        chatSideBar ? "overflow-y-hidden" : "overflow-y-scroll"
                      }  chat-ui-container flex flex-col`}
                      style={{
                        height: "20rem",
                      }}
                    >
                      <div className="  grid grid-cols-1  px-4 py-4 space-y-12">
                        {userProviderChat?.map((item, index) =>
                          item.is_provider_message == "0" ? (
                            <>
                              <div
                                className="place-self-start text-left "
                                key={index}
                              >
                                <p className="text-gray-500 text-xs float-righ  py-1 mb-2">
                                  <span className="font-black text-xs  text-black capitalize">
                                    {item.is_admin_message == "1"
                                      ? ``
                                      : `(${item?.user?.first_name} ${item?.user?.last_name})`}
                                  </span>

                                  <br />
                                  <span className="text-xs float-left  text-gray-500">
                                    {item.is_admin_message == "1" ? (
                                      <img src={loginLogo.default} width="80" />
                                    ) : (
                                      providerLabels.user
                                    )}
                                  </span>
                                </p>
                                <div className="bg-white p-5 shadow-md rounded-2xl rounded-tl-none render-html-class">
                                  {item?.message[0]?.message === null ? null : (
                                    <InformationContainer
                                      info={item?.message[0]?.message || ""}
                                    />
                                  )}

                                  {item?.message[0]?.documents?.map(
                                    (docItem, index) => {
                                      return (
                                        <a
                                          key={index}
                                          href={`${docItem.url}`}
                                          target="_blank"
                                          download={docItem?.name}
                                        >
                                          <div className="flex justify-between space-x-1">
                                            <img
                                              src={document?.default}
                                              className="document_icon"
                                            />
                                            <p className="text-sm">
                                              {docItem?.name}
                                            </p>
                                          </div>
                                        </a>
                                      );
                                    }
                                  )}
                                </div>
                                <p className="text-xs float-right mr-2 mt-2 text-gray-500">
                                  {`${moment(item?.timestamp).format(
                                    "H:mm"
                                  )} | ${moment(item?.timestamp).format(
                                    "DD-MMM-YYYY"
                                  )}`}
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="place-self-end">
                                <div className=" shadow-md  border-primary-message-sender  p-5 rounded-2xl rounded-tr-none render-html-class">
                                  {item?.message[0]?.message === null ? null : (
                                    <InformationContainer
                                      info={item?.message[0]?.message || ""}
                                    />
                                  )}
                                  {item?.message[0]?.documents?.map(
                                    (docItem, index) => {
                                      return (
                                        <a
                                          href={`${docItem.url}`}
                                          target="_blank"
                                          download={docItem?.name}
                                        >
                                          <div className="flex justify-between space-x-1">
                                            <img
                                              src={document?.default}
                                              className="document_icon"
                                            />
                                            <p className="text-sm">
                                              {docItem?.name}
                                            </p>
                                          </div>
                                        </a>
                                      );
                                    }
                                  )}
                                </div>
                                <p className="text-xs float-left ml-2 mt-2 text-gray-500">
                                  {" "}
                                  {`${moment(item?.timestamp).format(
                                    "H:mm"
                                  )} | ${moment(item?.timestamp).format(
                                    "DD-MMM-YYYY"
                                  )}`}
                                </p>
                              </div>
                            </>
                          )
                        )}
                      </div>
                      <div ref={messagesEndRef} />
                    </div>
                  )
                ) : null}
                {index === 1 ? (
                  <div
                    className={`bg-white  float-right overflow-y-hidden side-bar-scroll w-full`}
                    style={{
                      height: "40rem",
                    }}
                  >
                    <div className="  grid grid-cols-1   py-4 space-y-12">
                      <div className="my-5">
                        <h3 className="ml-8	">
                          {providerLabels.attachedDocuments}
                        </h3>
                      </div>
                      <div className="chatDocsDiv">
                        <Collapse>
                          <Collapse.Panel
                            header={providerLabels.myDocuments}
                            key="1"
                          >
                            {myChatDocuments.length === 0 ? (
                              <p>N/A</p>
                            ) : (
                              <div>
                                <div id="scrollableDiv">
                                  <List
                                    dataSource={myChatDocuments}
                                    renderItem={(item) =>
                                      item?.message[0]?.documents?.map(
                                        (docItem, index) => {
                                          return (
                                            <List.Item
                                              id="scrollableDiv"
                                              className="sideBarDocumentsList"
                                            >
                                              <a
                                                href={`${docItem.url}`}
                                                target="_blank"
                                                download={docItem?.name}
                                              >
                                                <div className="flex justify-between space-x-1">
                                                  <img
                                                    src={document?.default}
                                                    className="document_icon"
                                                  />
                                                  <p className="text-sm">
                                                    {docItem?.name}
                                                  </p>
                                                </div>
                                              </a>
                                            </List.Item>
                                          );
                                        }
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </Collapse.Panel>
                          <Collapse.Panel
                            header={providerLabels.userDocuments}
                            key="2"
                            // extra={extraDownloadAllUserFiles()}
                          >
                            {userChatDocuments.length === 0 ? (
                              <p>N/A</p>
                            ) : (
                              <div>
                                <div id="scrollableDiv" className="">
                                  <List
                                    dataSource={userChatDocuments}
                                    renderItem={(item) =>
                                      item?.message[0]?.documents?.map(
                                        (docItem, index) => {
                                          return (
                                            <List.Item
                                              id="scrollableDiv"
                                              className="sideBarDocumentsList"
                                            >
                                              <a
                                                href={`${docItem.url}`}
                                                target="_blank"
                                                download={docItem?.name}
                                              >
                                                <div className="flex justify-between space-x-1">
                                                  <img
                                                    src={document?.default}
                                                    className="document_icon"
                                                  />
                                                  <p className="text-sm">
                                                    {docItem?.name}
                                                  </p>
                                                </div>
                                              </a>
                                            </List.Item>
                                          );
                                        }
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </Collapse.Panel>
                          <Collapse.Panel
                            header={providerLabels.adminDocuments}
                            key="3"
                            // extra={extraDownloadAllUserFiles()}
                          >
                            {adminChatDocuments.length === 0 ? (
                              <p>N/A</p>
                            ) : (
                              <div>
                                <div id="scrollableDiv" className="">
                                  <List
                                    dataSource={adminChatDocuments}
                                    renderItem={(item) =>
                                      item?.message[0]?.documents?.map(
                                        (docItem, index) => {
                                          return (
                                            <List.Item
                                              id="scrollableDiv"
                                              className="sideBarDocumentsList"
                                            >
                                              <a
                                                href={`${docItem.url}`}
                                                target="_blank"
                                                download={docItem?.name}
                                              >
                                                <div className="flex justify-between space-x-1">
                                                  <img
                                                    src={document?.default}
                                                    className="document_icon"
                                                  />
                                                  <p className="text-sm">
                                                    {docItem?.name}
                                                  </p>
                                                </div>
                                              </a>
                                            </List.Item>
                                          );
                                        }
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </Collapse.Panel>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                ) : null}
                {index === 2 ? (
                  <div
                    className={`bg-white  overflow-y-hidden  notification-ui-container  `}
                    style={{
                      height: "40rem",
                    }}
                  >
                    <div className="  grid grid-cols-1  px-4 py-4 space-y-12">
                      <List
                        itemLayout="horizontal"
                        size="large"
                        header={
                          <div>
                            {" "}
                            <h3 className="ml-8	">
                              {providerLabels.orderNotification}
                            </h3>
                          </div>
                        }
                        dataSource={orderNotification}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  size="large"
                                  src={
                                    item.type === "budget"
                                      ? additionalBudgetIcon.default
                                      : item.type === "further"
                                      ? orderPlacedIcon.default
                                      : item.type === "completed"
                                      ? orderPlacedIcon.default
                                      : item.type === "canceled"
                                      ? orderCanceledIcon.default
                                      : item.type === "placed"
                                      ? orderPlacedIcon.default
                                      : null
                                  }
                                />
                              }
                              title={
                                <h4>
                                  {
                                    providerLabels?.[
                                      functionToConvertStringToCamelCase(
                                        item?.notification
                                      )
                                    ]
                                  }
                                </h4>
                              }
                              description={
                                <div>{`${moment(item?.created_at).format(
                                  "H:mm"
                                )} | ${moment(item?.created_at).format(
                                  "DD-MMM-YYYY"
                                )}`}</div>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </Spin>
          </div>
        </div>
        <ModalComponent
          filterModal={filterModal}
          closeModal={() => setFilterModal(false)}
          modalTitle={providerLabels.addBudget}
          width={420}
        >
          <div className="mt-2">
            <label className="lab1 mb-0 pb-0">
              {providerLabels.pleaseIndicateTheServiceNameAndQuoteNumber}
            </label>
            <div className="flex justify-center">
              <div className="richTextContainer text-center">
                <ReactQuill
                  className={"richTextBox shadow-lg w-full"}
                  value={value}
                  onChange={(content, delta, source, editor) => {
                    setValue(content);
                    if (editor.getText().trim()?.length === 0) {
                      setValue("");
                    }
                  }}
                />
                {(value === "" || value == null) && fieldIsRequired === true ? (
                  <span className="text-red-500">
                    {providerLabels.pleaseFillTheField}
                  </span>
                ) : null}
                <div
                  className="file btn btn-lg   btn-primary text-left uploadDiv"
                  style={{ width: "120px", height: "32px" }}
                >
                  <span className="btn bg-primary text-white py-2 rounded-3xl cursor-pointer">
                    {additionalBudgetDocument.length > 0
                      ? providerLabels.fileUploaded
                      : providerLabels.uploadFiles}

                    <input
                      type="file"
                      className="uploadInput cursor-pointer"
                      multiple
                      accept=".jpg, .jpeg, .png, .doc, .docx, .docs, .pdf, .xls, .csv, .xslx , .docx , .xlsx"
                      onChange={(e) => {
                        let f = e.target.files;
                        let fileArray = [];
                        for (var i = 0; i < f.length; i++) {
                          fileArray.push(f[i]);
                        }
                        selectMuiltipleBudgetDocument(fileArray);
                      }}
                    />
                  </span>
                </div>
                {addBudgetUrls.length > 0 && (
                  <div className="pt-2 flex justify-between flex-wrap">
                    {addBudgetUrls?.map((item, index) => {
                      return (
                        <div className="flex justify-between px-1  w-28">
                          <div>
                            {item?.name.substring(0, 5)}
                            {item?.name?.length > 5 &&
                              "... " + item?.name?.split(".")[1]}{" "}
                          </div>
                          <div
                            className="cursor-pointer	"
                            onClick={() => fileprev(item, index)}
                          >
                            {" "}
                            <DeleteOutlined
                              className="cursor-pointer	"
                              style={{ color: "red" }}
                            />{" "}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                <div className="flex flex-col justify-between mb-0 mt-3">
                  <label className="lab1">{providerLabels.estimatedTime}</label>

                  <div className="flex justify-around items-center  ">
                    <Dropdown
                      overlay={menu1}
                      className={` select-input py-2 px-3 mr-4 justify-center rounded-full w-64  bg-primary`}
                      trigger={["click"]}
                      disabled={props.loader === true ? true : false}
                    >
                      <a
                        className="flex justify-around items-center ant-dropdown-link h-10 capitalize"
                        style={{ color: "#fff" }}
                        onClick={(e) => e.preventDefault()}
                      >
                        {providerLabels[selectedTimeType] ||
                          providerLabels.timeType}
                        <DownOutlined className=" ml-10" />
                      </a>
                    </Dropdown>
                    <div className=" select-input  px-3  justify-center rounded-full  ">
                      <InputComponent
                        type="number"
                        classNames="text-gray-400 font-medium mt-0"
                        placeholder={providerLabels.enterTime}
                        value={serviceTime || ""}
                        onChange={(e) =>
                          setServiceTime(String(Math.abs(e.target.value)))
                        }
                        disabled={props.loader === true ? true : false}
                        onKeyDown={functionToPreventSignsFromNumberFromPricing}
                      />
                    </div>
                  </div>
                  {(serviceTime === null || selectedTimeType === null) &&
                  fieldIsRequired === true ? (
                    <span className="text-red-500">
                      {providerLabels.pleaseFillTheField}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="flex mt-0 justify-between items-center">
                  <label className="lab1">
                    {providerLabels.budgetVATIncluded}
                  </label>
                  <div className="w-2/5">
                    <div className="flex items-center">
                      <InputComponent
                        placeholder="0"
                        className=""
                        type="text"
                        value={budget || ""}
                        onChange={(e) =>
                          setBudget(
                            regexForPricing(e.target.value, e.nativeEvent) || 0
                          )
                        }
                        //onKeyDown={functionToPreventSignsFromNumberFromPricing}
                      />
                      <span className=" font-black mx-1">€</span>
                    </div>
                  </div>
                </div>
                {(budget === null || budget === "" || Number(budget < 0)) &&
                fieldIsRequired === true ? (
                  <span className="text-red-500">
                    {" "}
                    {providerLabels.pleaseFillTheField}
                  </span>
                ) : null}
                <div className="flex my-0 justify-between">
                  <label className="lab1">
                    {providerLabels.docuDocDeduction}
                  </label>
                  <label className="lab1">30%</label>
                </div>
                <div className="flex my-2 justify-between">
                  <label className="lab1">{providerLabels.totalAmount}</label>
                  <label className="lab1 truncate   w-44 flex justify-end">
                    {europeanCurrencyFormatter(
                      (Number(
                        budget === ""
                          ? 0
                          : Number(
                              regexToReplaceDecimalsWithComma(budget || "0")
                            )
                      ) /
                        100) *
                        70
                    )}{" "}
                    €
                  </label>
                </div>
                {/* <div className="flex my-0 justify-between flex-col items-center supplementary_Charges_option">
                  <div className="flex justify-start w-full items-center space-x-2">
                    <label className="lab1">
                      {providerLabels.supplementaryCharges}
                    </label>
                    <input
                      value={isSupplementaryCharges}
                      checked={isSupplementaryCharges}
                      onChange={(e) => {
                        setIsSupplementaryCharges(e.target.checked);
                      }}
                      type="checkbox"
                    />
                  </div>
                  <div
                    className={`w-full ${
                      !isSupplementaryCharges ? "hidden" : "block"
                    }`}
                  >
                    <div className={`step-container overflow-clip`}>
                      <Steps
                        progressDot
                        direction="vertical"
                        current={current}
                        className="steps_Dot_Style flex add-class-here"
                        size="small"
                      >
                        {stepsIncrement?.map((item, index) => (
                          <Steps.Step
                            key={item.id}
                            className="items-center	"
                            title={
                              <div className="stepper-add-button">
                                <div className="flex w-full items-center">
                                  <InputComponent
                                    width="100%"
                                    onChange={(e) => handleText(e, index)}
                                    placeholder={providerLabels.addTitle}
                                    value={item.name}
                                  />
                                  <InputComponent
                                    type="text"
                                    width="100%"
                                    onChange={(e) => handleAmount(e, index)}
                                    placeholder={providerLabels.addAmount}
                                    value={item.price}
                                    pattern="[0-9]"
                                  />
                                </div>
                              </div>
                            }
                            description={
                              stepsIncrement.length > 1 ? (
                                <Button
                                  className="bg-danger text-white ml-2 "
                                  shape="circle"
                                  onClick={() => prev(item.id, index)}
                                >
                                  -
                                </Button>
                              ) : null
                            }
                          />
                        ))}
                        <div className="stepper-add-button">
                          <Button
                            className=" shadow-md bg-primary text-white border-primary-message-sender  "
                            shape="circle"
                            onClick={next}
                          >
                            +
                          </Button>
                        </div>
                      </Steps>
                    </div>
                  </div>
                </div> */}
                {isSupplementaryCharges &&
                stepsIncrement.some(
                  (supItem) =>
                    supItem?.name?.trim()?.length === 0 ||
                    Number(supItem?.price) < 1
                ) &&
                fieldIsRequired === true ? (
                  <span className="text-red-500">
                    {providerLabels.pleaseFillTheField}
                  </span>
                ) : null}

                <div className="flex my-2 justify-between">
                  <label className="lab1"> {providerLabels.grandTotal}</label>
                  <label className="lab1">
                    {" "}
                    {europeanCurrencyFormatter(
                      (Number(
                        budget === ""
                          ? 0
                          : Number(
                              regexToReplaceDecimalsWithComma(budget || "0")
                            )
                      ) /
                        100) *
                        70 +
                        (isSupplementaryCharges === true
                          ? stepsIncrement
                              .map((item) =>
                                Number(
                                  regexToReplaceDecimalsWithComma(item?.price)
                                )
                              )
                              .reduce((prev, curr) => prev + curr, 0)
                          : 0)
                    )}{" "}
                    €
                  </label>
                </div>
                <div className="flex my-2 justify-between">
                  <label className="lab1">{providerLabels.userWillPay}</label>
                  <label className="lab1">
                    {" "}
                    {europeanCurrencyFormatter(
                      Number(
                        budget === ""
                          ? 0
                          : regexToReplaceDecimalsWithComma(budget || "0")
                      ) +
                        (isSupplementaryCharges === true
                          ? stepsIncrement
                              .map((item) =>
                                Number(
                                  regexToReplaceDecimalsWithComma(item?.price)
                                )
                              )
                              .reduce((prev, curr) => prev + curr, 0)
                          : 0)
                    )}{" "}
                    €
                  </label>
                </div>
              </div>
            </div>
            <button
              className="w-full bg-primary rounded-full py-3 text-white mt-4"
              onClick={addOrderBudget}
              disabled={isBudgetSendLoading}
            >
              {isBudgetSendLoading
                ? providerLabels.loading
                : providerLabels.SendBudget}
            </button>
          </div>
        </ModalComponent>
      </section>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  return {
    providerDetail: state.providerLoginDetail.loggedUserDetailOfProvider,
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddMyMessageOrder);
