import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { AiFillStar } from "react-icons/ai";
import { BsCheckCircle, BsCircle } from "react-icons/bs";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import { ModalComponent } from "../../components";
import { connect, useSelector } from "react-redux";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Provider_Reducers } from "../../components/dataRemoveOnProviderLogout";
import InputComponent from "../../components/InputComponent";
import {
  AssignOrderToWorker,
  GetOrdersById,
  GetWorkersByCapacity,
  GetOrderWorkingHours,
  ServiceProviderOrderReplyMethod,
  ServiceWorkerOrderReplyMethod,
} from "../../config/api";
import { providerLabels } from "../../config/label/providerLabels";
import { functionToConvertStringToCamelCase, padLeadingZeros } from "../../config/utils";
import { Images } from "../../constant/Images";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction,
} from "../../Redux/actions/fetchWorkersAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
function OrderInfoDetail(props) {
  const { goBack, push } = useHistory();
    const orders = useSelector((state) => state?.serviceProviderAllOrders?.orders?.orders);

  const [selectedOrder, setSelectedOrder] = useState({});
  const [serviceWorker, setServiceWorker] = useState(null);
  const [assignManager, setAssignManager] = useState(false);
  const [workerOfCapacity, setWorkerOfCapacity] = useState([]);
  const [workerProviderId, setWorkerProviderId] = useState("");
  const [selectedOrderHours, setSelectedOrderHours] = useState(null);
  const [searchWorker, setSearchWorker] = useState("");
  const [loading, setLoading] = useState(true);
  const currentSelectedLangue = localStorage.getItem("primaryLanguage");
  useEffect(() => {
    getOrdersByProvider(props.match.params.id);
    getOrderCountDownData(props.match.params.id);
    getWorkersOfCapacity();
    replyFunction(props.match.params.id);
  }, []);

  useEffect(() => {
    getWorkersOfCapacity();
  }, [selectedOrder]);

  useEffect(() => {
    const serviceProviderOrderCount = orders?.filter((item) => item.worker_provider_id !== null && ["pending", "dispute"]?.includes(item?.status))
   
    setWorkerOfCapacity((prev) => prev?.map((item) => {
      if(item?.is_worker === 0) {
        return{
          ...item,
          service_orders_count: Number(serviceProviderOrderCount?.length) || 0,
        }
      }else {
        return {
          ...item,
          service_orders_count: Number(item?.orders_dispute_count) + Number(item?.orders_pending_count) || 0
        }
        
      }
    }))
  },[orders])
  
  const onChecked = (e) => {
    const array = workerOfCapacity?.map((a, b) => {
      if (a?.service_provider_id === e?.service_provider_id) {
        setWorkerProviderId(a?.service_provider_id);
        return {
          ...a,
          name: a?.name,
          checked: !a.checked,
        };
      } else {
        return {
          ...a,
          name: a?.name,
          checked: false,
        };
      }
    });
    setWorkerOfCapacity(array);
  };
  const getWorkersOfCapacity = () => {
    const serviceProviderOrderCount = orders?.filter((item) => item.worker_provider_id !== null && ["pending", "dispute"]?.includes(item?.status))
    setLoading(true);
    GetWorkersByCapacity(
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            let mappedOut = success?.data?.response?.detail
              ?.filter((item) => item?.status === 1)
              ?.map((item, index) => {
                return {
                  ...item,
                  no: index + 1,
                  checked: selectedOrder?.worker_provider_id === item?.service_provider_id,
                  service_orders_count: Number(item?.orders_dispute_count) + Number(item?.orders_pending_count) || 0
                };
              });
            let serviceProvidervalue = JSON?.parse(
              JSON?.parse(localStorage.getItem("persist:root"))
                .providerLoginDetail
            ).loggedUserDetailOfProvider.admin;
            mappedOut.unshift({...serviceProvidervalue , service_orders_count : serviceProviderOrderCount?.length});
            setWorkerOfCapacity(mappedOut);
            setLoading(false);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };

  const getOrderCountDownData = (id) => {
    setLoading(true);
    const obj = {
      order_id: id,
    };
    GetOrderWorkingHours(
      obj,
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            setSelectedOrderHours(success?.data?.response?.detail);
            setLoading(false);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const getOrdersByProvider = (id) => {
    setLoading(true);
    GetOrdersById(
      id,
      (success) => {
        if (success.status === 200) {
          const data = success.data.response.detail;
          if (data !== null) {
            setSelectedOrder(data);
            if (data?.co_worker) {
              setServiceWorker(data?.co_worker);
            }
            setLoading(false);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };

  const assignOrderToWorker = () => {
    if (workerProviderId !== "") {
      let obj = {
        order_id: props.match.params.id,
        worker_provider_id: workerProviderId,
      };
      props.setLoaderState(true);

      AssignOrderToWorker(
        obj,
        (success) => {
          getOrdersByProvider(props.match.params.id);

          if (success.status === 200) {
            props.setLoaderState(false);
            Success(success.data.response.message);
            setTimeout(() => {
              setAssignManager(false);
            }, 500);
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Provider_Reducers(props);
            push("/");
          } else if (error?.response?.status === 409) {
            Errors(error?.response?.data?.error?.message);
            props.setLoaderState(false);
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
        }
      );
    } else {
      Errors("please select worker for order assign");
    }
  };
  const replyFunction = (orderId) => {
    const obj = {
      order_id: orderId,
    };

    if (localStorage.getItem("type") === "co_worker") {
      ServiceWorkerOrderReplyMethod(
        obj,
        (res) => {
          if (res?.data?.status) {
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Provider_Reducers(props);
            push("/");
          } else if (error?.response?.status === 409) {
            Errors(error?.response?.data?.error?.message);
            props.setLoaderState(false);
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
        }
      );
    }
    if (localStorage.getItem("type") === "service_provider") {
      ServiceProviderOrderReplyMethod(
        obj,
        (res) => {
          if (res?.data?.status) {
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Provider_Reducers(props);
            push("/");
          } else if (error?.response?.status === 409) {
            Errors(error?.response?.data?.error?.message);
            props.setLoaderState(false);
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
        }
      );
    }
  };
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <span>
        {padLeadingZeros(days)} days {padLeadingZeros(hours)}h :{" "}
        {padLeadingZeros(minutes)}m : {padLeadingZeros(seconds)}s
      </span>
    );
  };
  return (
    <Spin tip={providerLabels.loading} spinning={loading}>
      <section>
        <div className="flex justify-between mt-20 mb-5">
          <div className="pl-5 flex self-center justify-center items-center">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{providerLabels.back}</span>
            </div>
            <h3 className="ml-10	">
              {providerLabels.orderID}:{selectedOrder?.order_id}
            </h3>
          </div>
        </div>
        <div className="container grid grid-cols-12 gap-3 lg:pl-20 px-2 sm:px-2 lg:px-0">
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex mb-5">
            <h3 className="ml-8	my-4">{providerLabels.currentStep}</h3>
            <span
              className="flex items-center"
              style={{
                fontWeight: "normal",
                fontSize: 20,
                color: "#7D7D7D",
                marginLeft: 10,
                marginTop: 12,
              }}
            >
              {providerLabels?.[functionToConvertStringToCamelCase(selectedOrder?.service?.service_statuses?.findLast((currentStep) => currentStep?.order_status !==null)?.status)] || 'N/A'}
            </span>
          </div>
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-5">
            <div className="bg-white container rounded-lg ">
              <div style={{ display: "flex" }} className="p-5 pb-0">
                <img
                  className="rounded-3xl"
                  src={
                    serviceWorker
                      ? serviceWorker?.avatar
                      : props.providerDetail?.admin?.avatar
                  }
                  style={{ height: 48 }}
                />
                <div className="pl-2">
                  <span className="flex space-x-2">
                    <strong className=" capitalize">
                      {serviceWorker
                        ? serviceWorker?.name
                        : props.providerDetail?.admin?.name}
                    </strong>

                    {localStorage.getItem("type") === "service_provider" ? (
                      serviceWorker ? null : (
                        <>
                          <div className="flex items-center ">
                            <span
                              className="text-sm mr-1"
                              style={{ color: "#808080" }}
                            >
                              &#40;
                            </span>
                            <p className="text-sm mb-0">
                              {parseFloat(
                                props.providerDetail.admin.rating
                                  ? props.providerDetail.admin.rating
                                  : 0
                              ).toFixed(1)}
                            </p>
                            <AiFillStar
                              color={"#FFC107"}
                              style={{
                                marginRight: 5,
                                marginTop: 3,
                                fontSize: "13px",
                              }}
                            />
                            <span
                              className="text-sm mr-1"
                              style={{ color: "#808080" }}
                            >
                              &#41;
                            </span>
                          </div>
                        </>
                      )
                    ) : null}
                  </span>
                  <p>
                    {serviceWorker
                      ? serviceWorker?.email
                      : props.providerDetail?.admin?.email}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-2 p-6 pl-3 pt-0 pb-3">
                {/* /////////////////details */}
                <div className="" style={{ position: "relative" }}>
                  {false && (
                    <div
                      style={{
                        border: "1px solid gray",
                        opacity: 0.2,
                        marginBottom: "15px",
                      }}
                    />
                  )}
                  <div style={{ paddingLeft: 20 }}>
                    <b>{providerLabels.phoneNumber}</b>
                    <p>{serviceWorker
                      ? serviceWorker?.phone
                      : props.providerDetail?.admin?.phone}</p>
                  </div>
                </div>
                <div className="" style={{ position: "relative" }}>
                  {false && (
                    <div
                      style={{
                        border: "1px solid gray",
                        opacity: 0.2,
                        marginBottom: "15px",
                      }}
                    />
                  )}
                  <div style={{ paddingLeft: 20 }}>
                    <b>{providerLabels.serviceName}</b>
                    <p>
                      {
                        selectedOrder?.service?.[
                          currentSelectedLangue === "english"
                            ? "name"
                            : "name_sp"
                        ]
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 p-6 pl-3 pt-0 pb-3">
                {/* /////////////////details */}
                <div className="" style={{ position: "relative" }}>
                  {false && (
                    <div
                      style={{
                        border: "1px solid gray",
                        opacity: 0.2,
                        marginBottom: "15px",
                      }}
                    />
                  )}
                  <div style={{ paddingLeft: 20 }}>
                    <b>{providerLabels.registeredDate}</b>
                    <p>
                      {moment(props.providerDetail?.admin?.created_at).format(
                        "DD-MMM-YYYY"
                      )}
                    </p>
                  </div>
                </div>
                <div className="" style={{ position: "relative" }}>
                  {false && (
                    <div
                      style={{
                        border: "1px solid gray",
                        opacity: 0.2,
                        marginBottom: "15px",
                      }}
                    />
                  )}
                  <div style={{ paddingLeft: 20 }}>
                    <b>{providerLabels.workingHours}</b>
                    {localStorage.getItem("type") === "co_worker" ? (
                      <div className="flex flex-col items-start">
                        <div
                          className={` flex flex-col items-start text-xs w-full ${
                            props?.providerDetail?.admin?.provider
                              ?.working_hours?.length === 1
                              ? `py-2`
                              : `py-0`
                          }`}
                        >
                          {" "}
                          {props?.providerDetail?.admin?.provider?.working_hours?.map(
                            (hour, index) => {
                              return hour?.isFriday === false ? (
                                <div
                                  className=" w-full flex flex-wrap justify-between"
                                  key={index}
                                >
                                  <span className="w-1/2">
                                    {providerLabels?.monToThu} : {hour?.start} -{" "}
                                    {hour?.end}
                                  </span>
                                  {hour?.hasOwnProperty("monTHuLunchHours") &&
                                    hour?.monTHuLunchHours !== null && (
                                      <span className=" w-1/2 text-right">
                                        {providerLabels?.lunchTime} :{" "}
                                        {hour?.monTHuLunchHours?.start} -{" "}
                                        {hour?.monTHuLunchHours?.end}
                                      </span>
                                    )}
                                </div>
                              ) : (
                                <div
                                  className="w-full flex justify-between flex-wrap"
                                  key={index}
                                >
                                  <span className="w-1/2">
                                    {providerLabels?.fri} : {hour?.start} -{" "}
                                    {hour?.end}
                                  </span>
                                  {hour?.hasOwnProperty("fridayLunchHours") &&
                                    hour?.fridayLunchHours !== null && (
                                      <span className=" w-1/2 text-right">
                                        {providerLabels.lunchTime} :{" "}
                                        {hour?.fridayLunchHours?.start} -{" "}
                                        {hour?.fridayLunchHours?.end}
                                      </span>
                                    )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col items-start">
                        <div
                          className={` flex flex-col items-start text-xs w-full ${
                            props?.providerDetail?.admin?.working_hours
                              ?.length === 1
                              ? `py-2`
                              : `py-0`
                          }`}
                        >
                          {" "}
                          {props?.providerDetail?.admin?.working_hours?.map(
                            (hour, index) => {
                              return hour?.isFriday === false ? (
                                <div
                                  className="flex-wrap w-full flex justify-between"
                                  key={index}
                                >
                                  <span className="w-1/2">
                                    {providerLabels?.monToThu} : {hour?.start} -{" "}
                                    {hour?.end}
                                  </span>
                                  {hour?.hasOwnProperty("monTHuLunchHours") &&
                                    hour?.monTHuLunchHours !== null && (
                                      <span className=" w-1/2 text-right">
                                        {providerLabels?.lunchTime} :{" "}
                                        {hour?.monTHuLunchHours?.start} -{" "}
                                        {hour?.monTHuLunchHours?.end}
                                      </span>
                                    )}
                                </div>
                              ) : (
                                <div
                                  className="w-full flex justify-between"
                                  key={index}
                                >
                                  <span className="w-1/2">
                                    {providerLabels?.fri} : {hour?.start} -{" "}
                                    {hour?.end}
                                  </span>
                                  {hour?.hasOwnProperty("fridayLunchHours") &&
                                    hour?.fridayLunchHours !== null && (
                                      <span className=" w-1/2 text-right">
                                        {providerLabels.lunchTime} :{" "}
                                        {hour?.fridayLunchHours?.start} -{" "}
                                        {hour?.fridayLunchHours?.end}
                                      </span>
                                    )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 mb-2 flex">
              {localStorage.getItem("type") === "service_provider" ? (
                <ButtonComponent
                  className="bg-primary m-2"
                  title={providerLabels.reassign}
                  onClick={() => setAssignManager(true)}
                />
              ) : null}
              <Link
                className={`bg-primary  text-white h-6  rounded-full rounded-lg h-12 ${
                  localStorage.getItem("type") === "service_provider"
                    ? "w-full"
                    : "w-96"
                } bg-primary m-2`}
                to={
                  localStorage.getItem("type") === "co_worker"
                    ? `/worker/add-my-message-order/${selectedOrder.order_id}`
                    : `/add-my-message-order/${selectedOrder.order_id}`
                }
              >
                <ButtonComponent title={providerLabels.contactClient} />
              </Link>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-3">
            <div className="bg-white container rounded-lg px-5 py-3">
              <h3 className="hh3 ">{providerLabels.orderInfoAndDocument}</h3>
              <div className="mt-4 mb-2">
                {selectedOrder.length === 0 ? (
                  <ButtonComponent
                    className="bg-primary"
                    title={"Details"}
                    onClick={() => setAssignManager(true)}
                  />
                ) : (
                  <Link
                    to={{
                      pathname:
                        localStorage.getItem("type") === "co_worker"
                          ? "/worker/order-info-client"
                          : "/order-info-client",
                      state: { orderDetail: selectedOrder },
                    }}
                  >
                    <ButtonComponent
                      className="bg-primary"
                      title={providerLabels.details}
                    />
                  </Link>
                )}
              </div>
            </div>
            <div className="bg-white container rounded-lg px-5 py-3 mt-5">
              <h3 className="hh3">
                {providerLabels.theStepsToFlowUntilDelivery}
              </h3>
              <div className="mt-4 mb-2">
                <Link
                  to={
                    localStorage.getItem("type") === "co_worker"
                      ? `/worker/order-steps/${selectedOrder.order_id}`
                      : `/order-steps/${selectedOrder.order_id}`
                  }
                  params={{ OrderID: selectedOrder.order_id }}
                >
                  <ButtonComponent
                    className="bg-primary"
                    title={providerLabels.details}
                  />
                </Link>
              </div>
            </div>
            {/* {selectedOrder?.status === "dispute" && (
              <div className="py-3 mt-3">
                <Button
                  shape={"round"}
                  size="large"
                  className={`icon-button mx-1 bg-danger`}
                  onClick={() =>
                    push(`/dispute-chat/${selectedOrder?.order_id}`)
                  }
                >
                  <span className="text-white">
                    {providerLabels?.endDispute}
                  </span>
                </Button>
              </div>
            )} */}
          </div>
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-3">
            <div className="bg-white container rounded-lg px-5 py-3">
              <h3 className="hh3">{providerLabels.countDown}</h3>
              <p className="font-color-blue py-4 ">
                <Countdown
                  daysInHours={true}
                  date={Date.now() + (7.258e9 - 1.5797222222222222)}
                  renderer={renderer}
                />
              </p>
              <div className="mt-4 mb-2"></div>
            </div>
            <div className="bg-white container rounded-lg px-5 py-3 mt-5 pb-5">
              <h3 className="hh3">{providerLabels.orderStatus}</h3>
              <p className="font-color-blue pt-2 mb-1">
                {moment(selectedOrder?.created_at).format("DD-MMM-YYYY")}
              </p>
              <p className="font-color-red mb-0">
                {providerLabels.dueDateOfService}
              </p>
            </div>
          </div>
        </div>
        <ModalComponent
          filterModal={assignManager}
          closeModal={() => setAssignManager(false)}
          modalTitle={providerLabels.assignWorker}
        >
          <div>
            <div className="justify-center">
              <div className="grid grid-cols-6">
                <div className="col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6 shadow-md rounded-full">
                  <InputComponent
                    placeholder={providerLabels.search}
                    onChange={(e) => setSearchWorker(e.target.value)}
                    value={searchWorker}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between mt-4 mb-1">
                <span className="lab2">{providerLabels.workers}</span>
                <span className="lab2 mr-5">
                  {providerLabels.orderCapicity}
                </span>
              </div>
              <div className="select-serviceProvider-modal-container h-96 overflow-y-scroll px-2 py-2">
                {workerOfCapacity?.length
                  ? workerOfCapacity
                      ?.filter((item) => {
                        return item?.name
                          ?.toLowerCase()
                          ?.includes(searchWorker);
                      })
                      ?.map((t, i) => {
                        return (
                          <div
                            className="flex justify-between text-left py-5 "
                            key={i}
                          >
                            <div className="flex items-center justify-between">
                              <img
                                width={"40px"}
                                src={
                                  t.avatar !== null
                                    ? t.avatar
                                    : Images.profile.default
                                }
                                alt="logo alt"
                              />
                              <span className="pl-4 pr-1 capitalize">
                                {t.name}
                              </span>
                            </div>
                            {t.checked ? (
                              <div className="flex items-center">
                                <span className="pr-12">{t.service_orders_count}</span>
                                <BsCheckCircle
                                  size={20}
                                  color={"#55b319"}
                                  onClick={() => onChecked(t)}
                                />
                              </div>
                            ) : (
                              <div className="flex items-center">
                                <span className="pr-12">{t.service_orders_count}</span>
                                <BsCircle
                                  size={20}
                                  color={"#55b319"}
                                  onClick={() => onChecked(t)}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })
                  : null}
              </div>

              <button
                className="w-full bg-primary rounded-full py-3 text-white mt-4"
                onClick={assignOrderToWorker}
                disabled={props.loader === true ? true : false}
              >
                {providerLabels.finalizeAssignation}
                {props.loader === true ? <Spin indicator={antIcon} /> : ""}
              </button>
            </div>
          </div>
        </ModalComponent>
      </section>
    </Spin>
  );
}
const mapStateToProps = (state) => {
  return {
    showWorkers: state.workerList.workers,
    providerDetail: state.providerLoginDetail.loggedUserDetailOfProvider,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderInfoDetail);
